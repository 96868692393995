exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/Archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-companies-js": () => import("./../../../src/templates/Companies.js" /* webpackChunkName: "component---src-templates-companies-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/Company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/People.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/Person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

